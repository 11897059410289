import {_render, getCookie, updateSubscriberHash, copyToClipboardAndFocus} from '../../util';
import { $rivo } from '../../widget/loy/storage';

const js = document.createElement("script");
js.src = "https://cdnjs.cloudflare.com/polyfill/v3/polyfill.js?features=fetch";
document.head.appendChild(js);

window._init = function() {
  const widgetConfig = $rivo.widgets_config;
	const widgetId = widgetConfig.id;
  const widgetLogo = widgetConfig.logo;
  const widgetInitialState = widgetConfig.initial_state;
  const widgetSuccessState = widgetConfig.success_state;
  const widgetClosedState = widgetConfig.closed_state;
  const usedStickyBar = widgetClosedState.action == "show_sticky_bar";
  const widgetErrorState = widgetConfig.error_state;
  const widgetStickyCouponBar = widgetConfig.sticky_coupon_bar;
  const emailEnabled = String(widgetInitialState.show_email) == "true";
  const nameEnabled = String(widgetInitialState.show_first_last_name) == "true";
  const phoneNumberEnabled = String(widgetInitialState.show_phone_number) == "true";
  const emailOptedIn = parseInt($rivo.visitor.subscriber_hash["ba_email_opted_in"]) > 0;
  const smsOptedIn = parseInt($rivo.visitor.subscriber_hash["ba_sms_opted_in"]) > 0;
  const canShowPhoneField = phoneNumberEnabled && smsOptedIn == false;
  const canShowEmailField = emailEnabled && emailOptedIn == false;
  const canShowNameField = nameEnabled;

  function setCloseButton() {
    return `<a href="#" id="close_ba_widget" class="close_ba_widget ba_top_right_close" aria-label="Close widget">
			<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z" fill="" fill-rule="evenodd"></path></svg>
		</a>`;
  }

  function setBackgroundImageWrapper() {
    if(widgetConfig.has_background && ["left", "right"].includes(widgetConfig.background_image.position)) {
      return `<div class="ba_widget_bg ba_widget_${widgetConfig.background_image.position}_content"></div>`;
    }
    return "";
  }

  function setUpWidgetContent(body, display = "block") {
    return `<div id="ba-widget-${widgetId}-modal" class="ba-widget-main" data-rpoint="ba_widget_modal" style="display: ${display};">
			<div class="ba_widget_main_design ${widgetConfig.display_style.size} ${canShowPhoneField ? 'sms_enabled' : ''}">
				<div class="ba_widget_parent ${widgetConfig.has_background ? widgetConfig.background_image.position : "none"}">
					${setBackgroundImageWrapper()}
					<div class="ba_widget_content">
						${widgetConfig.dismissable ? setCloseButton() : ""}
						<div class="ba_widget_logo_holder">
							${widgetLogo ? `<img src='${widgetLogo}'>` : ""}
						</div>
						${body}
					</div>
				</div>
			</div>
      <div class="powered_by_rivo">Powered by <a data-rpoint="rivo_link" aria-label="Powered by Tydal" href="https://www.tydal.reviews/?utm_source=widget&utm_campaign=powered-by">TYDAL</a></div>
		</div>`;
  }

  function setFooterContent(){
    if (canShowPhoneField){
      return `<div class="ba_initial_state_sms_agreement">
        By signing up via text, you agree to receive recurring automated marketing messages, including cart reminders, at the phone number provided.
        Consent is not a condition of purchase. Reply <strong>STOP</strong> to unsubscribe. Reply <strong>HELP</strong> for help.
        Message frequency varies. Msg & data rates may apply.
        View our <a href="/policies/privacy-policy" data-rpoint="privacy" class="ba_link_color">Privacy Policy</a> and <a href="/policies/terms-of-service" data-rpoint="terms" class="ba_link_color">Terms of Service</a>.
      </div>`;
    } else {
      return `<div class="ba_initial_state_footer_text">${widgetInitialState.footer_text}</div>`;
    }
  }

  function setPhoneNumberField(widgetInitialState) {
    if (canShowPhoneField) {
      return `<input type="tel" id="ba_widget_phone_input" data-rpoint="phone_number" placeholder="${widgetInitialState.phone_placeholder || ""}">`;
    }
    return "";
  }

  function setEmailField(widgetInitialState) {
    if (canShowEmailField) {
      return `<input type="text" id="ba_widget_email_input" data-rpoint="email_address" placeholder="${widgetInitialState.email_placeholder || ""}">`;
    }
    return "";
  }

  function setNameField(widgetInitialState) {
    if (canShowNameField) {
      return `
      <div class="widget-name-holder">
        <input type="text" id="ba_widget_first_name_input" data-rpoint="first_name" placeholder="First Name">
        <input type="text" id="ba_widget_last_name_input" data-rpoint="last_name" placeholder="Last Name">
      </div>`;
    }
    return "";
  }

  function copyToClipboard(input) {
    copyToClipboardAndFocus(input, {copyClass: "ba-copy", successClass: 'ba-copy-success'});
  };

  function removeWidget() {
    window.parent.postMessage(JSON.stringify({action: "ba.widget.remove_modal", widget_id: widgetId}), "*");
  }

  function openLinkUrl(url){
    window.parent.postMessage(JSON.stringify({action: "ba.widget.go_to_url", url: url}), "*");
  }

  // Powered by Tydal
  function initRivoLink(_rpoints){
    _rpoints.rivo_link && _rpoints.rivo_link.addEventListener("click", event => {
      event.preventDefault();
      openLinkUrl(event.target.getAttribute("href"));
    });
  }

  async function ipInfo(callback, ip) {
    const request = new XMLHttpRequest();
    request.open("GET", "//ipinfo.io/" + ((ip) ? ip : ""), false);
    request.setRequestHeader("Accept", "application/json");
    request.onreadystatechange = function() {
      if (request.readyState == 4 && request.status == 200) {
        callback(request.responseText);
      }
    };
    request.send(null);
  }

  function initEventListener() {
    // close widget actions
    document.querySelectorAll(".close_ba_widget").forEach(item => {
      item.addEventListener("click", event => {
        event.preventDefault();
        if(widgetStickyCouponBar.enabled == "true" && getCookie(`ba_widget_${widgetId}_discount_code`) !== null){
          window.parent.postMessage(JSON.stringify({action: "ba.widget.show_sticky_coupon_bar", widget_id: widgetId, message: widgetStickyCouponBar.message}), '*');
        }
        if(getCookie(`ba_widget_${widgetId}_subscribed`) == "true" || !usedStickyBar){
          removeWidget();
          return;
        }
        if(usedStickyBar) window.parent.postMessage(JSON.stringify({action: "ba.widget.show_sticky_bar", widget_id: widgetId}), '*');
      });
    });
  }

  const initialStateBody = `
		<div class="ba_widget_content_holder">
			<h1 class="ba_initial_state_title">${widgetInitialState.title}</h1>
			<div class="ba_initial_state_body">${widgetInitialState.body}</div>
		</div>
		<form action="#" id="booster-widget-${widgetId}-form" data-rpoint="form">
			${setNameField(widgetInitialState)}
			${setEmailField(widgetInitialState)}
			${setPhoneNumberField(widgetInitialState)}
			<div class="ba-messages-wrapper">
				<div class="alert alert-danger ba_widget_error" data-rpoint="alert_danger" style="display:none;"></div>
			</div>
			<button type="submit" data-rpoint="button" id="ba_widget_cta_button" class="ba_initial_state_button">${widgetInitialState.cta_text}</button>
		</form>
		<div class="ba_widget_footer">
			<div class="ba_initial_state_dismiss_text close_ba_widget">
				${widgetInitialState.dismiss_text}
			</div>
      ${setFooterContent()}
		</div>`;

  const successStateBody = `
		<div class="ba_widget_content_holder">
			<h1 class="ba_success_state_title">${widgetSuccessState.title}</h1>
			<div class="ba_success_state_body" data-rpoint="success_state_body">${widgetSuccessState.body}</div>
		</div>
		<div class="ba_widget_copy_discount" data-rpoint="success_state_discount_body">
			<input type="text" name="ba_discount_code" data-rpoint="discount_code" id="ba_widget_discount_code_input">
			<button data-rpoint="copy_code" class="ba-copy-button ba-copy" aria-label="Copy discount code to clipboard"></button>
		</div>

		<button type="button" data-rpoint="finish_subscription" id="ba_widget_cta_button" class="ba_success_state_button">${widgetSuccessState.cta_text}</button>`;

  const renderedInitialState = _render(setUpWidgetContent(initialStateBody, "none"));

  const renderedSuccessState = _render(setUpWidgetContent(successStateBody, "block"));

  function subscriptionFinish(code, email_opted_in, sms_opted_in) {
    let _rpoints = renderedSuccessState.rpoints;
    let discountCodeInput = _rpoints.discount_code;
    let initialModal = document.getElementById(`ba-widget-${widgetId}-modal`);
    initialModal.replaceWith(renderedSuccessState.node);

    if (email_opted_in){
      updateSubscriberHash({ba_email_opted_in: parseInt(new Date().getTime())})
    }

    if (sms_opted_in){
      updateSubscriberHash({ba_sms_opted_in: parseInt(new Date().getTime())})
    }

    if (code) {
      discountCodeInput.value = code;
      if(widgetStickyCouponBar.enabled == "true"){
        window.parent.postMessage(JSON.stringify({action: "ba.widget.save_discount_code", widget_id: widgetId, code: code}), '*');
      }
    } else {
      _rpoints.success_state_discount_body.remove();
    }

    _rpoints.copy_code.addEventListener("click", function() {
      copyToClipboard(discountCodeInput);
      localStorage.setItem(`ba_widget_${widgetId}_copied_discount`, "1");
    });

    _rpoints.finish_subscription.addEventListener("click", function() {
      if (code && widgetStickyCouponBar.enabled == "true") window.parent.postMessage(JSON.stringify({action: "ba.widget.show_sticky_coupon_bar", widget_id: widgetId, message: widgetStickyCouponBar.message}), '*');
      if (code && localStorage.getItem(`ba_widget_${widgetId}_copied_discount`) != "1") copyToClipboard(discountCodeInput);
      if (widgetSuccessState.cta_action == "open_url") {
        if(widgetSuccessState.open_url_new_tab == "true"){
          window.parent.postMessage(JSON.stringify({action: "ba.widget.go_to_url", url: widgetConfig.success_state.redirect_url}), "*");
          removeWidget();
        }else{
          window.parent.postMessage(JSON.stringify({action: "ba.widget.redirect_url"}), '*');
        }
      } else {
        setTimeout(function(){
          removeWidget();
        }, 500);
      }
    });

    initRivoLink(_rpoints);
    initEventListener();
  }

  function resetForm() {
    _rpoints.email_address.value = "";
    if (canShowPhoneField) _rpoints.phone_number.value = "";
  }

  function resetButton() {
    _rpoints.button.removeAttribute("disabled");
    _rpoints.button.innerHTML = widgetInitialState.cta_text;
    _rpoints.button.style.display = "block";
  }

  function messageAlerts(mode, text) {
    _rpoints.alert_danger.style.display = "none";

    if (mode) {
      if (text) _rpoints["alert_" + mode].innerHTML = text;
      _rpoints["alert_" + mode].style.display = "";
    }
  }

  function optInVisitor(additional_data = {}) {
		let subscriber_params = {
			customer_id: $rivo.common.customer ? $rivo.common.customer.id : null,
			email: _rpoints.email_address && _rpoints.email_address.value,
      first_name: _rpoints.first_name && _rpoints.first_name.value,
      last_name: _rpoints.last_name && _rpoints.last_name.value,
      timezone: $rivo.visitor.browser_info.tz_offset,
      os: $rivo.visitor.browser_info.os,
      browser: $rivo.visitor.browser_info.browser
		};

    let payload = {
      widget_id: widgetId,
      shop: $rivo.common.shop.permanent_domain,
      visitor_token: $rivo.visitor.visitor_token,
			subscriber: {...subscriber_params, ...additional_data}
    };

    const widgetProxyAppType = $rivo.global_config.proxy_paths["pu"] || $rivo.global_config.proxy_paths["pop"];

    fetch(widgetProxyAppType + "/subscribers", {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(function(resp) {
      resp.json().then(function(data) {
        if (data.success) {
          subscriptionFinish(data.discount_code, data.email_opted_in, data.sms_opted_in);
          window.parent.postMessage(JSON.stringify({action: "ba.widget.set_subscribed_cookie", widget_id: widgetId}), '*');
        } else {
          let err_msg = "";
          if(data.error_type == "already_registered"){
            err_msg = widgetErrorState.already_registered || "You have already registered"
          }else{
            err_msg = widgetErrorState.error_subscribing || "Error subscribing, try again later"
          }
          messageAlerts("danger", err_msg);
          resetButton();
        }
      });
    }).catch(function(err) {
      console.log(err);
      messageAlerts("danger", widgetErrorState.submit_error || "Sorry, please try again later");
      resetButton();
    });
  }

  const mailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  function resetAlertMsg(target_field="") {
    _rpoints.alert_danger.style.display = "none";
    _rpoints.alert_danger.innerHTML = "";
    if (target_field == "email") _rpoints.email_address && _rpoints.email_address.classList.remove("error");
    if (target_field == "phone") _rpoints.phone_number && _rpoints.phone_number.classList.remove("error");
  }

  function isValidEmail() {
    if (!_rpoints.email_address.value.match(mailRegex)) {
      _rpoints.alert_danger.style.display = "block";
      _rpoints.alert_danger.innerHTML = widgetConfig.error_state.invalid_email || "Enter a valid email address";
      _rpoints.email_address.classList.add("error");
      return false;
    } else {
      resetAlertMsg("email");
      return true;
    }
  }

  function isValidPhoneNumber(intlPhoneNumber) {
    if (!intlPhoneNumber.isValidNumber()) {
      _rpoints.alert_danger.style.display = "block";
      _rpoints.alert_danger.innerHTML = widgetConfig.error_state.invalid_phone_number || "Enter a valid phone number";
      _rpoints.phone_number.classList.add("error");
      return false;
    } else {
      resetAlertMsg("phone");
      return true;
    }
  }

  let documentBody = document.body;
  documentBody.className = "ba-widget-overlay";
  documentBody.appendChild(renderedInitialState.node);

  let _rpoints = renderedInitialState.rpoints;

	// set email of logged-in customer
	if($rivo.common.customer && canShowEmailField){
		_rpoints.email_address.value = $rivo.common.customer.email;
	}

  // Privacy & Terms Link Event
  _rpoints.privacy && _rpoints.privacy.addEventListener("click", event => {
    event.preventDefault();
    openLinkUrl(event.target.getAttribute("href"));
  });

  _rpoints.terms && _rpoints.terms.addEventListener("click", event => {
    event.preventDefault();
    openLinkUrl(event.target.getAttribute("href"));
  });

  initRivoLink(_rpoints);

  // Intl Telephone Input
  let intlPhoneNumber;
  let phoneNumberInput = document.querySelector("#ba_widget_phone_input");
  try {
    intlPhoneNumber = window.intlTelInput(phoneNumberInput, ({
      hiddenInput: "full_phone_number",
      separateDialCode: true,
      initialCountry: "auto",
      geoIpLookup: function(callback) {
        if (getCookie("ba_widget_country_code")) {
          callback(getCookie("ba_widget_country_code"));
          return;
        }

        let countryCode;
        try {
          ipInfo(function(resp) {
            countryCode = (resp && JSON.parse(resp).country) ? JSON.parse(resp).country : "us";
            document.cookie = `ba_widget_country_code=${countryCode}; max-age=${3600 * 24}; path=/`;
          });
        } catch (e) {
          countryCode = "us";
        }
        callback(countryCode);
      },
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js"
    }));

    phoneNumberInput.classList.add("intlTelInput");

    phoneNumberInput.addEventListener("countrychange", function() {
      let itiContainerWidth = document.querySelector(".iti__flag-container").offsetWidth + 4;
      let newPaddingLeft = itiContainerWidth > 89 ? itiContainerWidth : 89;
      phoneNumberInput.setAttribute("style", `padding-left: ${newPaddingLeft}px !important`);
    });
  } catch (e) {
    // slide
  }

  let stickyBarInitiated = getCookie(`ba_widget_${widgetId}_show_sticky_bar`) == "true";
  let stickyBarActivated = usedStickyBar && stickyBarInitiated;

  if(widgetConfig.trigger.action == "on_page_exit" && !stickyBarActivated && window.matchMedia("(min-device-width: 768px)").matches){
    // Show widget when exiting page
    _rpoints.ba_widget_modal.style.display = "block";
    initEventListener();
    window.parent.postMessage(JSON.stringify({action: "ba.widget.init_exit_page_intent", widget_id: widgetId}), '*');
  }else{
    // Show widget based on set delay
    let showDelay = parseInt(widgetConfig.trigger.delay_in_seconds || '0') * 1000;
    if(stickyBarActivated) showDelay = 0;

    setTimeout(function() {
      _rpoints.ba_widget_modal.style.display = "block";
      initEventListener();

      if(!stickyBarInitiated){
        window.parent.postMessage(JSON.stringify({action: "ba.widget.show_widget_modal", widget_id: widgetId}), '*');
      }
    }, showDelay);
  }


  function canSubmitForm(){
    if(canShowEmailField && isValidEmail()) return true;
    if(canShowPhoneField && isValidPhoneNumber(intlPhoneNumber)) return true;
    return false;
  }

  function triggerEmailKeyUp(){
    _rpoints.email_address && _rpoints.email_address.addEventListener("keyup", isValidEmail);
  }

  function triggerPhoneNumberKeyUp(){
    _rpoints.phone_number && _rpoints.phone_number.addEventListener("keyup", function() {
      isValidPhoneNumber(intlPhoneNumber);
    });
  }

  _rpoints.form.addEventListener("submit", function(e) {
    e.stopPropagation();
    e.preventDefault();

    if (widgetConfig.active) {
      let additional_data = {};

      // Email Address & Phone Number Validation
      if(canShowEmailField && canShowPhoneField){
        resetAlertMsg();

        if(!_rpoints.email_address.value.length && !_rpoints.phone_number.value.length) {
          _rpoints.email_address.focus();
          return;
        }

        if(_rpoints.email_address.value.length && _rpoints.phone_number.value.length) {
          if(!isValidEmail()) {
            _rpoints.email_address.focus();
            return;
          }
          if(!isValidPhoneNumber(intlPhoneNumber)){
            _rpoints.phone_number.focus();
            return;
          }
        }

        if(_rpoints.email_address.value.length && !_rpoints.phone_number.value.length) {
          _rpoints.phone_number.classList.remove("error");
          if(!isValidEmail()) {
            _rpoints.email_address.focus();
            triggerEmailKeyUp();
            return;
          }
        }

        if(_rpoints.phone_number.value.length && !_rpoints.email_address.value.length) {
          _rpoints.email_address.classList.remove("error");
          if(!isValidPhoneNumber(intlPhoneNumber)){
            _rpoints.phone_number.focus();
            triggerPhoneNumberKeyUp();
            return;
          }
        }
      }

      // Email Address Validation only
      if(canShowEmailField && !canShowPhoneField){
        if(!isValidEmail()) {
          _rpoints.email_address.focus();
          triggerEmailKeyUp();
          return;
        }
      }

      // Phone Number Validation only
      if (canShowPhoneField && !canShowEmailField) {
        if(!isValidPhoneNumber(intlPhoneNumber)){
          triggerPhoneNumberKeyUp();
          _rpoints.phone_number.focus();
          return;
        }
      }

      // pass country code + phone_number together
      if(canShowPhoneField){
        additional_data = {
          phone_number: intlPhoneNumber.getNumber(intlTelInputUtils.numberFormat.E164),
          country_code: intlPhoneNumber.getSelectedCountryData().iso2
        }
      }

      if(canSubmitForm()){
        resetAlertMsg("email");
        resetAlertMsg("phone");
      }else{
        return;
      }

      _rpoints.button.setAttribute("disabled", "disabled");

      messageAlerts();

      _rpoints.button.innerHTML = widgetInitialState.action_text || "Saving...";
      optInVisitor(additional_data);
    } else {
      closeWidgetModal();
    }

    return false;
  }, true);

  localStorage.removeItem(`ba_widget_${widgetId}_copied_discount`);
};
